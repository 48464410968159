import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Pagination" component="pagination" variation="pagination" hasReactVersion hasVueVersion="pagination--default" hasAngularVersion="?path=/story/pagination--default" codepen="KYzgVB" mdxType="ComponentCode" />
    <ComponentCode name="Pagination disabled buttons" component="pagination" variation="pagination--disabled-pagination-buttons" hasReactVersion codepen="LvNRZV" mdxType="ComponentCode" />
    <ComponentDocs component="pagination" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      